import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, NgZone, OnChanges,
    OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomComponentBase } from '@shared/common/custom-component-base';
import { TaskSnapshotComparisonState, TaskStatus } from '@shared/PullPlanningEnums';
import { PullPlanTaskTrackedModel } from '@shared/PullPlanTaskTrackedModel';
import { PullPlanTaskDto } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { MenuItem } from 'primeng/api';
import { ColourHelper } from '@shared/helpers/ColourHelper';
import { Menu } from 'primeng/menu';
import { SuperTooltip } from '@shared/utils/super-tooltip.directive';
import { DisplayModeEnum } from '../pullplan-whiteboard/pullplan-whiteboard.component';

@Component({
    selector: 'tasknote',
    templateUrl: './tasknote.component.html',
    styleUrls: ['./tasknote.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TaskNoteComponent),
            multi: true
        }
    ],
    host:{ "[attr.data-id]": "taskId" }
})
export class TaskNoteComponent extends CustomComponentBase implements OnInit, OnChanges, AfterViewInit {

    @ViewChild('tasknote', { read: ElementRef }) taskNoteElementRef: ElementRef;
    @ViewChild('tasknotebtn', { static: true }) tasknotebtn: HTMLButtonElement;
    @ViewChild('menu', { static: true }) menu: Menu;
    @ViewChild(SuperTooltip) tooltip: SuperTooltip; //ref to the super-tooltip directive

    @Input() type: number = 0;
    @Input() taskId: number = 0;
    @Input() taskDate: DateTime;
    @Input() taskDuration: number = 0;
    //@Input() taskLocation: string = '';
    @Input() taskLocationId?: number | undefined;
    @Input() taskLocationTitle: string = '';
    @Input() taskProgress: number = 0;
    @Input() taskPullPlanId: number = 0;
    @Input() taskResponsibility: string = '';
    @Input() taskStatusId: number = 0;
    // @Input() taskSwimlaneId?: number | undefined; // 21/08/2024 PMc #7265 - deprecated
    @Input() taskParentTaskId?: number | undefined;
    @Input() taskTeamSize?: number | undefined;
    @Input() taskTheme: string = '';
    @Input() taskTitle: string = '';
    @Input() taskNotes: string = ''; // 21/10/2024 PMc #8842 - added
    @Input() taskWorkArea: string = '';
    @Input() taskColumnCellIndex: number = 0;
    @Input() taskLocked: boolean = false;
    @Input() taskActualEffort: number = 0;
    @Input() taskActualTeamSize?: number | undefined = 0;
    @Input() taskProjectTeamId?: number | undefined;

    // 21/10/2024 PMc #8842 - Add task risk fields
    @Input() taskRiskCategoryId?: number | undefined;
    @Input() riskCategoryName?: string | undefined;
    @Input() taskRiskIdentifiedDate?: DateTime | undefined;
    @Input() taskRiskOwnerUserId?: number | undefined;
    @Input() riskOwnerName?: string | undefined;
    @Input() taskRiskResolveByDate?: DateTime | undefined;
    @Input() taskRiskResolvedDate?: DateTime | undefined;
    @Input() taskRiskNotes?: string | undefined;

    // @Input() locationLocked: boolean = false;
    @Input() swimlaneTeamTitle: string = '';
    @Input() swimlaneTeamColour: string = '';
    @Input() swimlaneTeamTextColour: string = '';
    @Input() scale: number = 1;
    @Input() dependency: string = '';
    @Input() linkedActivityName: string = '';
    @Input() pullPlanTask: PullPlanTaskDto | PullPlanTaskTrackedModel;
    @Input() dragndrop: boolean = false;
    @Input() dragndroplinks: boolean = false;
    @Input() hoverClick: boolean = false;
    @Input() hover: boolean = false;
    @Input() source: string = '';
    @Input() editable: boolean = false;
    @Input() hide: boolean = false;
    @Input() allowGrips: boolean = false;
    @Input() selected: boolean = false;
    @Input() showSelectCheckbox: boolean = false;
    @Input() readonly: boolean = false;
    @Input() snapshotComparisonState: TaskSnapshotComparisonState = TaskSnapshotComparisonState.NoChange;
    @Input() snapshotComparisonProgress: number = 0;
    @Input() showSnapshotComparisonIcons: boolean = false;
    @Input() slide: boolean = false;
    @Input() inTaskList: boolean = false;
    @Input() allowTooltip: boolean = false;
    @Input() displayMode: DisplayModeEnum = DisplayModeEnum.SwimlanesByProjectTeam;
    @Input() displayModeTeamFieldName: string = '';
    @Input() displayModeLocationFieldName: string = '';

    get compactViewField(): string {
        if (this.scale < 3) return '';
        
        let fieldName = '';
        if (this.displayMode == DisplayModeEnum.SwimlanesByProjectTeam) {
            fieldName = this.displayModeTeamFieldName;
        } else if (this.displayMode == DisplayModeEnum.SwimlanesByLocation) {
            fieldName = this.displayModeLocationFieldName;
        }
        switch (fieldName) {
            case 'TaskResponsibility': return this.swimlaneTeamTitle;
            case 'TaskLocation': return this.taskLocationTitle;
            case 'TaskWorkArea': return this.taskWorkArea;
            default: return '';
        }
    }

    @Output() DragEvent = new EventEmitter<ITaskDragEvent>();
    @Output() LinkDragEvent = new EventEmitter<ITaskDragEvent>();
    @Output() EditTask = new EventEmitter<number>();
    @Output() DuplicateTask = new EventEmitter<number>();
    @Output() RemoveTask = new EventEmitter<number>();
    @Output() DeleteTask = new EventEmitter<number>();
    @Output() CompleteTask = new EventEmitter<number>();
    @Output() MouseEvent = new EventEmitter<ITaskMouseEvent>();
    @Output() SelectTask = new EventEmitter<ITaskSelectData>();

    get strikethrough(): boolean {
        return (this.taskStatusId == 3);
    }

    get atRiskOrBlocked(): boolean {
        return (this.taskStatusId == 2);
    }

    get formattedDuration(): string {
        if (this.isNullOrUndefinedOrNaNOrZero(this.taskDuration)) { return ''; }
        return `${this.taskDuration} ${this.l('TaskDuration_HoursShort')}`;
    }

    get formattedActualEffort(): string {
        if (this.isNullOrUndefinedOrNaNOrZero(this.taskActualEffort)) { return ''; }
        return `${this.taskActualEffort} ${this.l('TaskDuration_HoursShort')}`;
    }

    get useLightStateIcon(): boolean {
        return (ColourHelper.getRecommendedTextColor(this.swimlaneTeamColour) == '#FFFFFF');
    }

    get taskProgressHidden(): boolean {
        return this.taskStatusId != TaskStatus.InProgress && this.taskStatusId != TaskStatus.AtRiskOrBlocked
            && !(this.showSnapshotComparisonIcons && this.zeroIfNull(this.snapshotComparisonProgress) != this.zeroIfNull(this.taskProgress) 
                && this.snapshotComparisonState != TaskSnapshotComparisonState.Added);
    }

    get taskStatusDescription(): string {
        switch (this.taskStatusId) {
            case TaskStatus.ToDo: return this.l('TaskStatus_ToDo');
            case TaskStatus.InProgress: return this.l('TaskStatus_InProgress');
            case TaskStatus.AtRiskOrBlocked: return this.l('TaskStatus_AtRiskOrBlocked');
            case TaskStatus.Complete: return this.l('TaskStatus_Complete');
            default: return '';
        }
    }

    private zeroIfNull(value: number): number {
        return value || 0;
    }

    private isTrackedModel = (obj: any): obj is PullPlanTaskTrackedModel => { return true; }

    TaskStatusEnum: typeof TaskStatus = TaskStatus;
    DisplayModeEnum: typeof DisplayModeEnum = DisplayModeEnum;
    TaskSnapshotComparisonStateEnum: typeof TaskSnapshotComparisonState = TaskSnapshotComparisonState;

    DEBUG = false;

    customStyles: string = ''; //this will be used to hold the positioning styles in the whiteboard
    menuitems: MenuItem[];
    tooltipClassName: string = 'tasknote-tooltip';
    menuButtonClicked: boolean = false;
    taskTooltipContent: string = '';

    constructor(
        private injector: Injector,
        private cdRef: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        let context = this;
        this.uiDragService.dragStart$.subscribe(() => {
            if (context.menu != null && context.menu.visible) {
                context.menu.hide();
                context.tooltip.deactivate();
                context.cdRef.detectChanges();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) { return; }
        if (changes.pullPlanTask) {
            this.refresh();
            let context = this;
            if (changes.pullPlanTask.currentValue && changes.pullPlanTask.currentValue.subscribe) {
                (changes.pullPlanTask.currentValue as PullPlanTaskTrackedModel).subscribe((e: string) => context.onModelChange(e));
            }
        }
        if (changes.taskProjectTeamId || changes.taskLocationId || changes.taskLocked || changes.taskStatusId || changes.readonly) {
            this.setMenuItems();
        }
    }

    ngAfterViewInit(): void {
    }

    private setMenuItems(): void {
        let subitems = [];

        if (this.readonly) {
            subitems.push({ label: this.l('WhiteboardViewTask'), icon: 'ds-icon-default-form', command: (event) => this.taskEditClick(), separator: false });
        } else {
            subitems.push({ label: this.l('WhiteboardEditTask'), icon: 'ds-icon-default-pen', command: (event) => this.taskEditClick(), separator: false });

            if (this.taskStatusId != TaskStatus.Complete && this.taskStatusId != TaskStatus.AtRiskOrBlocked && !this.taskLocked) {
                subitems.push({ label: this.l('WhiteboardTaskMarkAsComplete'), icon: 'ds-icon-default-check-square', command: (event) => this.taskComplete(), separator: false });
            }

            subitems.push({ label: this.l('WhiteboardDuplicateTask'), icon: 'ds-icon-default-stack', command: (event) => this.taskDuplicateClick(), separator: false });

            if (!this.taskLocked && !this.inTaskList) { //this.isNullOrUndefinedOrNaN(this.taskProjectTeamId) || !this.isNullOrUndefinedOrNaN(this.taskLocationId))) {
                subitems.push({ label: this.l('WhiteboardRemoveTask'), icon: 'ds-icon-default-download rotate-90', command: (event) => this.removeTaskClick(), separator: false });
            }

            if (!this.taskLocked) {
                subitems.push({ separator: true, label: '', icon: '', command: null });
                subitems.push({ label: this.l('WhiteboardDeleteTask'), icon: 'ds-icon-default-trash', command: (event) => this.taskDeleteClick(), separator: false });
            }
        }

        this.menuitems = [{ label: `<b>${this.taskTitle}</b>`, escape: false, items: subitems }];
    }

    private onModelChange(e: any) {
        if (this.DEBUG) { console.log('onModelChange:', e); }

        this.refresh();
    }

    public refresh(): void {
        if (!this.pullPlanTask) { return; }

        this.taskId = this.pullPlanTask.id;
        this.taskResponsibility = this.pullPlanTask.taskResponsibility;
        this.taskTeamSize = this.pullPlanTask.taskTeamSize;
        this.taskTitle = this.pullPlanTask.taskTitle;
        this.taskDate = this.pullPlanTask.taskDate;
        this.taskDuration = this.pullPlanTask.taskDuration;
        this.taskProgress = this.pullPlanTask.taskProgress;
        this.taskStatusId = this.pullPlanTask.taskStatusId;
        //this.taskLocation = this.pullPlanTask.taskLocation;
        this.taskLocationId = this.pullPlanTask.taskLocationId;
        this.taskLocationTitle = this.pullPlanTask.taskLocationTitle;
        this.taskWorkArea = this.pullPlanTask.taskWorkArea;
        this.taskNotes = this.pullPlanTask.taskNotes;
        this.taskLocked = this.pullPlanTask.taskLocked;
        // this.taskSwimlaneId = this.pullPlanTask.taskSwimlaneId; // 21/08/2024 PMc #7265 - deprecated
        this.taskParentTaskId = this.pullPlanTask.taskParentTaskId;
        this.taskColumnCellIndex = this.pullPlanTask.taskColumnCellIndex;
        this.taskTheme = this.pullPlanTask.taskTheme || 'yellow';
        this.taskActualEffort = this.pullPlanTask.taskActualEffort;
        this.taskActualTeamSize = this.pullPlanTask.taskActualTeamSize;
        this.taskProjectTeamId = this.pullPlanTask.taskProjectTeamId;
        // this.locationLocked = this.pullPlanTask.locationLocked;
        this.swimlaneTeamTitle = this.pullPlanTask.swimlaneTeamTitle;
        this.swimlaneTeamColour = this.pullPlanTask.swimlaneTeamColour;
        this.swimlaneTeamTextColour = this.pullPlanTask.swimlaneTeamTextColour;
        this.linkedActivityName = this.pullPlanTask.linkedActivityName;
        this.selected = this.pullPlanTask.selected;

        this.taskRiskCategoryId = this.pullPlanTask.taskRiskCategoryId;
        this.riskCategoryName = this.pullPlanTask.riskCategoryName;
        this.taskRiskIdentifiedDate = this.pullPlanTask.taskRiskIdentifiedDate;
        this.taskRiskOwnerUserId = this.pullPlanTask.taskRiskOwnerUserId;
        this.riskOwnerName = this.pullPlanTask.riskOwnerName;
        this.taskRiskResolveByDate = this.pullPlanTask.taskRiskResolveByDate;
        this.taskRiskResolvedDate = this.pullPlanTask.taskRiskResolvedDate;
        this.taskRiskNotes = this.pullPlanTask.taskRiskNotes;

        if (this.isTrackedModel) {
            this.snapshotComparisonState = (this.pullPlanTask as PullPlanTaskTrackedModel).snapshotComparisonState;
            this.snapshotComparisonProgress = (this.pullPlanTask as PullPlanTaskTrackedModel).snapshotComparisonProgress;
        }

        this.setMenuItems();

        this.buildTaskTooltipContent();
    }

    private getDataAsPullPlanTask(): PullPlanTaskDto {
        //PullPlanTaskDto has simple properties, whereas PullPlanTaskTrackedModel uses getters/setters;
        //when using JSON.stringify(), it will convert PullPlanTaskTrackedModel private variables
        //as it doesn't work with getters! (e.g. "taskTheme" will be output as "_taskTheme")

        let data: PullPlanTaskDto;
        if (typeof this.pullPlanTask != 'undefined' && this.pullPlanTask != null) {
            data = new PullPlanTaskDto();
            data.id = this.pullPlanTask.id;
            data.taskResponsibility = this.pullPlanTask.taskResponsibility;
            data.taskTeamSize = this.pullPlanTask.taskTeamSize;
            data.taskTitle = this.pullPlanTask.taskTitle;
            data.taskDate = this.pullPlanTask.taskDate;
            data.taskDuration = this.pullPlanTask.taskDuration;
            data.taskProgress = this.pullPlanTask.taskProgress;
            data.taskStatusId = this.pullPlanTask.taskStatusId;
            //data.taskLocation = this.pullPlanTask.taskLocation;
            data.taskLocationId = this.pullPlanTask.taskLocationId;
            data.taskLocationTitle = this.pullPlanTask.taskLocationTitle;
            data.taskWorkArea = this.pullPlanTask.taskWorkArea;
            data.taskNotes = this.pullPlanTask.taskNotes;
            data.taskLocked = this.pullPlanTask.taskLocked;
            // data.taskSwimlaneId = this.pullPlanTask.taskSwimlaneId; // 21/08/2024 PMc #7265 - deprecated
            data.taskParentTaskId = this.pullPlanTask.taskParentTaskId;
            data.taskTheme = this.pullPlanTask.taskTheme;
            data.taskActualEffort = this.pullPlanTask.taskActualEffort;
            data.taskActualTeamSize = this.pullPlanTask.taskActualTeamSize;
            data.taskProjectTeamId = this.pullPlanTask.taskProjectTeamId;
            data.taskColumnCellIndex = this.pullPlanTask.taskColumnCellIndex;
            data.swimlaneTeamTitle = this.pullPlanTask.swimlaneTeamTitle;
            data.swimlaneTeamColour = this.pullPlanTask.swimlaneTeamColour;
            data.swimlaneTeamTextColour = this.pullPlanTask.swimlaneTeamTextColour;
            data.linkedActivityName = this.pullPlanTask.linkedActivityName;
            // data.locationLocked = this.pullPlanTask.locationLocked;
            data.taskRiskCategoryId = this.pullPlanTask.taskRiskCategoryId;
            data.riskCategoryName = this.pullPlanTask.riskCategoryName;
            data.taskRiskIdentifiedDate = this.pullPlanTask.taskRiskIdentifiedDate;
            data.taskRiskOwnerUserId = this.pullPlanTask.taskRiskOwnerUserId;
            data.riskOwnerName = this.pullPlanTask.riskOwnerName;
            data.taskRiskResolveByDate = this.pullPlanTask.taskRiskResolveByDate;
            data.taskRiskResolvedDate = this.pullPlanTask.taskRiskResolvedDate;
            data.taskRiskNotes = this.pullPlanTask.taskRiskNotes;
        } else {
            data = new PullPlanTaskDto();
            data.id = this.taskId;
            data.taskResponsibility = this.taskResponsibility;
            data.taskTeamSize = this.taskTeamSize;
            data.taskTitle = this.taskTitle;
            data.taskDate = this.taskDate;
            data.taskDuration = this.taskDuration;
            data.taskProgress = this.taskProgress;
            data.taskStatusId = this.taskStatusId;
            //data.taskLocation = this.taskLocation;
            data.taskLocationId = this.taskLocationId;
            data.taskLocationTitle = this.taskLocationTitle;
            data.taskWorkArea = this.taskWorkArea;
            data.taskNotes = this.taskNotes;
            data.taskLocked = this.taskLocked;
            // data.taskSwimlaneId = this.taskSwimlaneId;
            data.taskParentTaskId = this.taskParentTaskId;
            data.taskTheme = this.taskTheme;
            data.taskActualEffort = this.taskActualEffort;
            data.taskActualTeamSize = this.taskActualTeamSize;
            data.taskProjectTeamId = this.taskProjectTeamId;
            data.taskColumnCellIndex = this.taskColumnCellIndex;
            data.swimlaneTeamTitle = this.swimlaneTeamTitle;
            data.swimlaneTeamColour = this.swimlaneTeamColour;
            data.swimlaneTeamTextColour = this.swimlaneTeamTextColour;
            data.linkedActivityName = this.linkedActivityName;
            //data.locationLocked = this.locationLocked;
            data.taskRiskCategoryId = this.taskRiskCategoryId;
            data.riskCategoryName = this.riskCategoryName;
            data.taskRiskIdentifiedDate = this.taskRiskIdentifiedDate;
            data.taskRiskOwnerUserId = this.taskRiskOwnerUserId;
            data.riskOwnerName = this.riskOwnerName;
            data.taskRiskResolveByDate = this.taskRiskResolveByDate;
            data.taskRiskResolvedDate = this.taskRiskResolvedDate;
            data.taskRiskNotes = this.taskRiskNotes;
        }
        return data;
    }

    onMenuButtonClick(ev: Event): void {
        //ev.stopPropagation();
        this.menuButtonClicked = true;
        this.hideToolTip();
        this.menu.toggle(ev);
    }

    onDragEvents(type: string, event: DragEvent): void {
        if (!this.dragndrop) { return; }

        if (this.DEBUG) { console.log(`onDragEvents(type: ${type}) taskId: ${this.taskId}, event:`, event); }

        switch (type) {

            case 'dragover':
                if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                    event.preventDefault();
                }
                if (!this.hover) { this.hover = true; }
                return; //there's little we can do with the dragover event as we can't access the data!

            case 'dragenter':
                //event.preventDefault();
                // if (event.dataTransfer.effectAllowed != 'link') {
                //   event.dataTransfer.dropEffect = 'none';
                // }
                //if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                if (!this.hover) { this.hover = true; }
                //}
                break;

            case 'dragleave':
                // if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                if (this.hover) { this.hover = false; }
                // }
                break;

            case 'drop':
                //if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                if (this.hover) { this.hover = false; }
                //}
                event.preventDefault();
                break;

            default:
                if (type == 'dragstart') {
                    this.setDragNDropData(event.dataTransfer, `task`, this.getDataAsPullPlanTask());
                }
                break;
        }

        this.DragEvent.emit(new TaskDragEvent(type, this.getDataAsPullPlanTask(), event));
    }

    onGripMouseEvents(type: string, event: DragEvent): void {
        switch (type) {
            case 'mouseover':
                //ensure tooltip is closed
                if (this.allowTooltip && this.tooltip != null) { this.tooltip.deactivate(); }
                break;

            case 'mouseout':
                //check if the mouse is over the non-grip area of the task note - if so, reactivate the tooltip
                if (this.allowTooltip && this.tooltip != null) {
                    //check if the mouse is within the bounds of the task note
                    if (this.taskNoteElementRef) {
                        let rect = this.taskNoteElementRef.nativeElement.getBoundingClientRect();
                        if (event.clientX >= rect.left && event.clientX <= rect.right && event.clientY >= rect.top && event.clientY <= rect.bottom) {
                            this.tooltip.activate();
                        }
                    }
                }
                break;
        }
    }

    onGripDragEvents(type: string, event: DragEvent): void {
        if (!this.dragndrop || !this.allowGrips) { return; }
        if (type == 'dragover') { return; }

        event.stopImmediatePropagation();

        let task = this.getDataAsPullPlanTask();
        let griptype = (event.target as HTMLElement).getAttribute('data-griptype');

        switch (type) {
            // case 'dragover':
            //   return; //there's little we can do with the dragover event as we can't access the data!

            case 'dragstart':
                //event.preventDefault(); //this stops the default drag image appearing
                //event.stopImmediatePropagation(); //this stops the parent task drag event from also firing!
                this.setDragNDropData(event.dataTransfer, `${griptype}-link`, task);
                
                //ensure the menu is closed
                if (this.menu != null) { this.menu.hide(); }

                //ensure the tooltip is closed
                this.hideToolTip();

                break;
        }

        this.LinkDragEvent.emit(new TaskDragEvent(griptype, task, event));
    }

    hideToolTip(): void {
        if (this.tooltip != null) { this.tooltip.deactivate(); }
    }

    onMouseEvents(type: string, event: DragEvent): void {
        if (type == 'click' && this.menuButtonClicked) {
            //we don't want to fire the click event if the menu button was clicked
            this.menuButtonClicked = false; //reset the flag
            return; 
        }
        let task = this.getDataAsPullPlanTask();
        this.MouseEvent.emit(new TaskMouseEvent(type, task));
    }

    taskEditClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.EditTask.emit(id);
    }

    taskDuplicateClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.DuplicateTask.emit(id);
    }

    removeTaskClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.RemoveTask.emit(id)
    }

    taskDeleteClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.DeleteTask.emit(id)
    }

    taskComplete(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.CompleteTask.emit(id);
    }

    // show(): void {
    //   this.hide = false;
    // }

    setCustomStyles(styles: string): void {
        this.customStyles = styles;
    }

    private setDragNDropData(dataTransfer: DataTransfer, type: string, task: PullPlanTaskDto): void {
        let data = new TaskDragData(type, this.source, task);
        let json = JSON.stringify(data);
        dataTransfer.setData('dragdata', json);
    }

    toggleSelection(ev: Event): void {
        this.selected = !this.selected;
        this.SelectTask.emit(new TaskSelectData(this.taskId, this.selected));
    }

    trimHTML(value: string): string {
        if (this.isNullOrUndefinedOrEmptyString(value)) { return ''; }
        value = value.trim();
        if (value.startsWith('<br>')) { value = value.slice(4); }
        if (value.startsWith('<br/>')) { value = value.slice(5); }
        if (value.startsWith('<br />')) { value = value.slice(6); }
        if (value.endsWith('<br>')) { value = value.slice(0, -4); }
        if (value.endsWith('<br/>')) { value = value.slice(0, -5); }
        if (value.endsWith('<br />')) { value = value.slice(0, -6); }
        if (value.startsWith('<p>') && value.endsWith('</p>')) { value = value.slice(3, -4); }
        return value.trim();
    }

    buildTaskTooltipContent(): void {
        let html = `<table class="tasknote-tooltip-content">
                <tr>
                    <td colspan="2"><b>${this.taskTitle}</b></td>
                </tr>
                <tr>
                    <td>${this.l('TaskResponsibility')}:</td>
                    <td>${this.swimlaneTeamTitle}</td>
                </tr>
                <tr>
                    <td>${this.l('TaskDate')}:</td>
                    <td>${this.taskDate?.toFormat('D')}</td>
                </tr>
                <tr>
                    <td>${this.l('TaskDuration')}:</td>
                    <td>${this.formattedDuration}</td>
                </tr>
                <tr>
                    <td>${this.l('TaskTeamSize')}:</td>
                    <td>${this.taskTeamSize}</td>
                </tr>
        `;

        if (!this.isNullOrUndefinedOrNaNOrZero(this.taskActualTeamSize)) {
            html += `
                <tr>
                    <td>${this.l('TaskActualTeamSize')}:</td>
                    <td>${this.taskActualTeamSize}</td>
                </tr>
            `;
        }

        if (!this.isNullOrUndefinedOrNaNOrZero(this.taskActualEffort)) {
            html += `
                <tr>
                    <td>${this.l('TaskActualEffort')}:</td>
                    <td>${this.formattedActualEffort}</td>
                </tr>
            `;
        }
        html += `
            <tr>
                <td>${this.l('TaskLocation')}:</td>
                <td>${this.taskLocationTitle}</td>
            </tr>
            `;

        if (!this.isNullOrUndefinedOrEmptyString(this.taskWorkArea)) {
            html += `
                <tr>
                    <td>${this.l('TaskWorkArea')}:</td>
                    <td>${this.taskWorkArea}</td>
                </tr>
            `;
        }

        html += `
            <tr>
                <td>${this.l('TaskLinkedActivity')}:</td>
                <td>${this.isNullOrUndefinedOrEmptyString(this.linkedActivityName) ? this.l('TaskActivityNotLinked') : this.linkedActivityName}</td>
            </tr>
            <tr>
                <td>${this.l('TaskStatus')}:</td>
                <td>${this.taskStatusDescription}</td>
            </tr>
            `;

        if (!this.isNullOrUndefinedOrNaN(this.taskProgress) && this.taskStatusId != TaskStatus.ToDo) {
            html += `
                <tr>
                    <td>${this.l('TaskProgress')}:</td>
                    <td>${this.taskProgress}%</td>
                </tr>
            `;
        }

        html += `
            <tr>
                <td>${this.l('TaskLocked')}:</td>
                <td>${this.taskLocked ? this.l('Locked') : this.l('Unlocked')}</td>
            </tr>
        `;

        if (!this.isNullOrUndefinedOrEmptyString(this.taskNotes)) {
            html += `
                <tr>
                    <td>${this.l('TaskNotes')}:</td>
                    <td>${this.trimHTML(this.taskNotes)}</td>
                </tr>
            `;
        }

        if (this.taskStatusId == TaskStatus.AtRiskOrBlocked) {
            html += `
                <tr>
                    <td>${this.l('RiskCategory')}:</td>
                    <td>${this.riskCategoryName}</td>
                </tr>
                <tr>
                    <td>${this.l('TaskRiskIdentifiedDate')}:</td>
                    <td>${this.taskRiskIdentifiedDate?.toFormat('D')}</td>
                </tr>
                <tr>
                    <td>${this.l('TaskRiskOwner')}:</td>
                    <td>${this.riskOwnerName}</td>
                </tr>
                <tr>
                    <td>${this.l('TaskRiskResolveByDate')}:</td>
                    <td>${this.taskRiskResolveByDate?.toFormat('D')}</td>
                </tr>
                
            `;

            if (this.taskRiskResolvedDate != null)
            {
                html += `
                    <tr>
                        <td>${this.l('TaskRiskResolvedDate')}:</td>
                        <td>${this.taskRiskResolvedDate?.toFormat('D')}</td>
                    </tr>
                `;
            }

            if (!this.isNullOrUndefinedOrEmptyString(this.taskRiskNotes)) {
                html += `
                    <tr>
                        <td>${this.l('TaskRiskNotes')}:</td>
                        <td>${this.taskRiskNotes?.trim()}</td>
                    </tr>
                `;
            }
        }
        html += '</table>';
        this.taskTooltipContent = html;
    }
}

export interface ITaskDragEvent {
    type: string;
    task: PullPlanTaskDto;
    event: DragEvent;
}

export class TaskDragEvent implements ITaskDragEvent {
    type: string;
    task: PullPlanTaskDto;
    event: DragEvent;

    constructor(type: string, task: PullPlanTaskDto, event: DragEvent) {
        this.type = type;
        this.task = task;
        this.event = event;
    }
}

export interface ITaskMouseEvent {
    type: string;
    task: PullPlanTaskDto;
}

export class TaskMouseEvent implements ITaskMouseEvent {
    type: string;
    task: PullPlanTaskDto;

    constructor(type: string, task: PullPlanTaskDto) {
        this.type = type;
        this.task = task;
    }
}

export interface ITaskDragData {
    type: string;
    source: string;
    task: PullPlanTaskDto;
}

export class TaskDragData implements ITaskDragData {
    type: string;
    source: string;
    task: PullPlanTaskDto;

    constructor(type?: string, source?: string, task?: PullPlanTaskDto) {
        this.type = type;
        this.source = source;
        this.task = task;
    }
}

export interface ITaskSelectData {
    id: number;
    selected: boolean;
}

export class TaskSelectData implements ITaskSelectData {
    id: number;
    selected: boolean;

    constructor(id: number, selected: boolean) {
        this.id = id;
        this.selected = selected;
    }
}
