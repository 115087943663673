import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CustomComponentBase } from '@shared/common/custom-component-base';
import { PullPlanTaskDto } from '@shared/service-proxies/service-proxies';
import { ITaskMouseEvent, TaskMouseEvent } from '../tasknote/tasknote.component';
import { TaskStatus } from '@shared/PullPlanningEnums';
import { DateTime } from 'luxon';

@Component({
    selector: 'baseline-tasknote',
    templateUrl: './baseline-tasknote.component.html',
    styleUrls: ['./baseline-tasknote.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BaselineTaskNoteComponent extends CustomComponentBase {

    private currentTask: PullPlanTaskDto = null;
    @Input() baselineTask: PullPlanTaskDto = null;
    @Input() hide: boolean = false;
    @Input() scale: number = 1;
    @Input() highlight: boolean = false;

    @Output() MouseEvent = new EventEmitter<ITaskMouseEvent>();

    get strikethrough(): boolean {
        return (this.baselineTask?.taskStatusId == 3);
    }

    get atRiskOrBlocked(): boolean {
        return (this.baselineTask?.taskStatusId == 2);
    }

    formattedDuration(value: number): string {
        if (this.isNullOrUndefinedOrNaNOrZero(value)) { return ''; }
        return `${value} ${this.l('TaskDuration_HoursShort')}`;
    }

    formattedActualEffort(value: number): string {
        if (this.isNullOrUndefinedOrNaNOrZero(value)) { return ''; }
        return `${value} ${this.l('TaskDuration_HoursShort')}`;
    }

    TaskStatusEnum: typeof TaskStatus = TaskStatus;

    customStyles: string = ''; //this will be used to hold the positioning styles in the whiteboard
    swimlaneTeamColour: string = '';
    swimlaneTeamTextColour: string = '';
    taskTooltipContent: string = '';

    constructor(
        private injector: Injector,
    ) {
        super(injector);
    }

    // ngOnInit(): void {
    // }
    
    // ngOnChanges(changes: SimpleChanges): void {
    //     if (!changes) { return; }
    //     this.rebuildTaskTooltipContent();
    // }

    public setCustomStyles(styles: string): void {
        this.customStyles = styles;
        this.swimlaneTeamColour = this.baselineTask.swimlaneTeamColour;
        this.swimlaneTeamTextColour = this.baselineTask.swimlaneTeamTextColour;
    }

    public setCurrentTask(task: PullPlanTaskDto): void {
        this.currentTask = task;
        Promise.resolve(null).then(() => {
            this.rebuildTaskTooltipContent();
        });
    }

    getTaskStatusDescription(task: PullPlanTaskDto): string {
        if (task == null) { return ''; }
        switch (task.taskStatusId) {
            case TaskStatus.ToDo: return this.l('TaskStatus_ToDo');
            case TaskStatus.InProgress: return this.l('TaskStatus_InProgress');
            case TaskStatus.AtRiskOrBlocked: return this.l('TaskStatus_AtRiskOrBlocked');
            case TaskStatus.Complete: return this.l('TaskStatus_Complete');
            default: return '';
        }
    }

    onMouseEvents(type: string, event: DragEvent): void {
        this.MouseEvent.emit(new TaskMouseEvent(type, this.baselineTask));
    }

    classCompare1(current: string, baseline: string): string {
        if (this.isNullOrUndefinedOrEmptyString(current)) { current = ''; }
        if (this.isNullOrUndefinedOrEmptyString(baseline)) { baseline = ''; }
        return (current == baseline) ? '' : 'changed';
    }

    classCompare2(current: number, baseline: number): string {
        if (this.isNullOrUndefinedOrNaNOrZero(current)) { current = 0; }
        if (this.isNullOrUndefinedOrNaNOrZero(baseline)) { baseline = 0; }
        return (current == baseline) ? '' : 'changed';
    }

    classCompare3(current: DateTime, baseline: DateTime): string {
        if (this.isNullOrUndefined(current) && this.isNullOrUndefined(baseline)) { return ''; }
        if (this.isNullOrUndefined(current) && !this.isNullOrUndefined(baseline)) { return 'changed'; }
        if (!this.isNullOrUndefined(current) && this.isNullOrUndefined(baseline)) { return 'changed'; }
        return (current?.toISODate() == baseline.toISODate()) ? '' : 'changed';
    }

    classCompare4(current: boolean, baseline: boolean): string {
        if (this.isNullOrUndefined(current)) { current = false; }
        if (this.isNullOrUndefined(baseline)) { baseline = false; }
        return (current == baseline) ? '' : 'changed';
    }

    emptyStringIfNull(value: number): string {
        return this.isNullOrUndefinedOrNaN(value) ? '' : value.toString();
    }

    trimHTML(value: string): string {
        if (this.isNullOrUndefinedOrEmptyString(value)) { return ''; }
        value = value.trim();
        if (value.startsWith('<br>')) { value = value.slice(4); }
        if (value.startsWith('<br/>')) { value = value.slice(5); }
        if (value.startsWith('<br />')) { value = value.slice(6); }
        if (value.endsWith('<br>')) { value = value.slice(0, -4); }
        if (value.endsWith('<br/>')) { value = value.slice(0, -5); }
        if (value.endsWith('<br />')) { value = value.slice(0, -6); }
        if (value.startsWith('<p>') && value.endsWith('</p>')) { value = value.slice(3, -4); }
        return value.trim();
    }

    rebuildTaskTooltipContent(): void {
        if (this.baselineTask == null) { return; }
        let compareTasks = this.currentTask != null;

        //taskTitle
        let html = `<table class="tasknote-tooltip-content" attr-id="${this.baselineTask.id}">`;
        if (compareTasks) {
            html += `<tr><td colspan="3"><b>${this.baselineTask.taskTitle}</b></td</tr>`;
            html += `<tr><td colspan="3" class="${this.classCompare1(this.currentTask.taskTitle, this.baselineTask.taskTitle)}"><b>${this.currentTask.taskTitle}</b></td</tr>`;
        } else {
            html += `<tr><td colspan="2"><b>${this.baselineTask.taskTitle}</b></td></tr>`;
        }

        if (compareTasks) {
            html += `<tr><td>&nbsp;</td><td><b>${this.l('BaselineTask')}</b></td><td><b>${this.l('CurrentTask')}</b></td></tr>`;
        }

        //swimlaneTeamTitle
        html += `<tr><td>${this.l('TaskResponsibility')}:</td><td>${this.baselineTask.swimlaneTeamTitle}</td>`;
        if (compareTasks) {
            html += `<td class="${this.classCompare1(this.currentTask.swimlaneTeamTitle, this.baselineTask.swimlaneTeamTitle)}">${this.currentTask.swimlaneTeamTitle}</td>`;
        }
        html += '</tr>';

        //taskDate
        html += `<tr><td>${this.l('TaskDate')}:</td><td>${this.baselineTask.taskDate?.toFormat('D')}</td>`;
        if (compareTasks) {
            html += `<td class="${this.classCompare3(this.currentTask.taskDate, this.baselineTask.taskDate)}">${this.currentTask.taskDate?.toFormat('D')}</td></tr>`;
        }
        html += '</tr>';

        //taskDuration
        html += `</tr><tr><td>${this.l('TaskDuration')}:</td><td>${this.formattedDuration(this.baselineTask.taskDuration)}</td>`;
        if (compareTasks) {
            html += `<td class="${this.classCompare2(this.currentTask.taskDuration, this.baselineTask.taskDuration)}">${this.formattedDuration(this.currentTask.taskDuration)}</td>`;
        }
        html += '</tr>';

        //taskTeamSize
        html += `<tr><td>${this.l('TaskTeamSize')}:</td><td>${this.emptyStringIfNull(this.baselineTask.taskTeamSize)}</td>`;
        if (compareTasks) {
            html += `<td class="${this.classCompare2(this.currentTask.taskTeamSize, this.baselineTask.taskTeamSize)}">${this.emptyStringIfNull(this.currentTask.taskTeamSize)}</td>`;
        }
        html += '</tr>';

        //taskActualTeamSize
        if (!this.isNullOrUndefinedOrNaNOrZero(this.baselineTask.taskActualTeamSize)
            || (compareTasks && this.classCompare2(this.currentTask.taskActualTeamSize, this.baselineTask.taskActualTeamSize) != '')) {
            html += `<tr><td>${this.l('TaskActualTeamSize')}:</td><td>${this.emptyStringIfNull(this.baselineTask.taskActualTeamSize)}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare2(this.currentTask.taskActualTeamSize, this.baselineTask.taskActualTeamSize)}">${this.emptyStringIfNull(this.currentTask.taskActualTeamSize)}</td>`;
            }
            html += '</tr>';
        }

        //taskActualEffort
        if (!this.isNullOrUndefinedOrNaNOrZero(this.baselineTask.taskActualEffort)
            || (compareTasks && this.classCompare2(this.currentTask.taskActualEffort, this.baselineTask.taskActualEffort) != '')) {
            html += `<tr><td>${this.l('TaskActualEffort')}:</td><td>${this.formattedActualEffort(this.baselineTask.taskActualEffort)}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare2(this.currentTask.taskActualEffort, this.baselineTask.taskActualEffort)}">${this.formattedActualEffort(this.currentTask.taskActualEffort)}</td>`;
            }
            html += '</tr>';
        }

        //taskLocation
        html += `<tr><td>${this.l('TaskLocation')}:</td><td>${this.baselineTask.taskLocationTitle}</td>`;
        if (compareTasks) {
            html += `<td class="${this.classCompare1(this.currentTask.taskLocationTitle, this.baselineTask.taskLocationTitle)}">${this.currentTask.taskLocationTitle}</td>`;
        }
        html += '</tr>';
        
        //taskWorkArea
        if (!this.isNullOrUndefinedOrEmptyString(this.baselineTask.taskWorkArea)
            || (compareTasks && this.classCompare1(this.currentTask.taskWorkArea, this.baselineTask.taskWorkArea) != '')) {
            html += `<tr><td>${this.l('TaskWorkArea')}:</td><td>${this.baselineTask.taskWorkArea}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare1(this.currentTask.taskWorkArea, this.baselineTask.taskWorkArea)}">${this.currentTask.taskWorkArea}</td>`;
            }
            html += '</tr>';
        }

        //linkedActivityName
        let linkedActivityName = this.isNullOrUndefinedOrEmptyString(this.baselineTask.linkedActivityName) ? this.l('TaskActivityNotLinked') : this.baselineTask.linkedActivityName;
        html += `<tr><td>${this.l('TaskLinkedActivity')}:</td><td>${linkedActivityName}</td>`;
        if (compareTasks) {
            let currentLinkedActivityName = this.isNullOrUndefinedOrEmptyString(this.currentTask.linkedActivityName) ? this.l('TaskActivityNotLinked') : this.currentTask.linkedActivityName;
            html += `<td class="${this.classCompare1(linkedActivityName, currentLinkedActivityName)}">${currentLinkedActivityName}</td>`;
        }
        html += '</tr>';

        //taskStatusId
        html += `<tr><td>${this.l('TaskStatus')}:</td><td>${this.getTaskStatusDescription(this.baselineTask)}</td>`;
        if (compareTasks) {
            html += `<td class="${this.classCompare2(this.currentTask.taskStatusId, this.baselineTask.taskStatusId)}">${this.getTaskStatusDescription(this.currentTask)}</td>`;
        }
        html += '</tr>';
        
        if (this.baselineTask.taskStatusId != TaskStatus.ToDo
            || (compareTasks && this.classCompare2(this.baselineTask.taskStatusId, this.currentTask.taskStatusId))) {
            html += `<tr><td>${this.l('TaskProgress')}:</td><td>${this.baselineTask.taskProgress}%</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare2(this.currentTask.taskProgress, this.baselineTask.taskProgress)}">${this.currentTask.taskProgress}%</td>`;
            }
            html += '</tr>';
        }

        html += `<tr><td>${this.l('TaskLocked')}:</td><td>${this.baselineTask.taskLocked ? this.l('Locked') : this.l('Unlocked')}</td>`;
        if (compareTasks) {
            html += `<td class="${this.classCompare4(this.currentTask.taskLocked, this.baselineTask.taskLocked)}">${this.currentTask.taskLocked ? this.l('Locked') : this.l('Unlocked')}</td>`;
        }
        html += '</tr>';

        if (!this.isNullOrUndefinedOrEmptyString(this.baselineTask.taskNotes)
            || (compareTasks && this.classCompare1(this.currentTask.taskNotes, this.baselineTask.taskNotes) != '')) {
            html += `<tr><td>${this.l('TaskNotes')}:</td><td>${this.trimHTML(this.baselineTask.taskNotes)}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare1(this.currentTask.taskNotes, this.baselineTask.taskNotes)}">${this.trimHTML(this.currentTask.taskNotes)}</td>`;
            }
            html += '</tr>';
        }

        if (this.baselineTask.taskStatusId == TaskStatus.AtRiskOrBlocked
            || (compareTasks && this.currentTask.taskStatusId == TaskStatus.AtRiskOrBlocked)) {

            html += `<tr><td>${this.l('RiskCategory')}:</td><td>${this.baselineTask.riskCategoryName}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare1(this.currentTask.riskCategoryName, this.baselineTask.riskCategoryName)}">${this.currentTask.riskCategoryName}</td>`;
            }
            html += '</tr>';
        
            html += `<tr><td>${this.l('TaskRiskIdentifiedDate')}:</td><td>${this.baselineTask.taskRiskIdentifiedDate?.toFormat('D')}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare3(this.currentTask.taskRiskIdentifiedDate, this.baselineTask.taskRiskIdentifiedDate)}">${this.currentTask.taskRiskIdentifiedDate?.toFormat('D')}</td>`;
            }
            html += '</tr>';

            html += `<tr><td>${this.l('TaskRiskOwner')}:</td><td>${this.baselineTask.riskOwnerName}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare1(this.currentTask.riskOwnerName, this.baselineTask.riskOwnerName)}">${this.currentTask.riskOwnerName}</td>`;
            }
            html += '</tr>';

            html += `<tr><td>${this.l('TaskRiskResolveByDate')}:</td><td>${this.baselineTask.taskRiskResolveByDate?.toFormat('D')}</td>`;
            if (compareTasks) {
                html += `<td class="${this.classCompare3(this.currentTask.taskRiskResolveByDate, this.baselineTask.taskRiskResolveByDate)}">${this.currentTask.taskRiskResolveByDate?.toFormat('D')}</td>`;
            }
            html += '</tr>';

            if (this.baselineTask.taskRiskResolvedDate != null)
            {
                html += `<tr><td>${this.l('TaskRiskResolvedDate')}:</td><td>${this.baselineTask.taskRiskResolvedDate?.toFormat('D')}</td>`;
                if (compareTasks) {
                    html += `<td class="${this.classCompare3(this.currentTask.taskRiskResolvedDate, this.baselineTask.taskRiskResolvedDate)}">${this.currentTask.taskRiskResolvedDate?.toFormat('D')}</td>`;
                }
                html += '</tr>';
            }

            if (!this.isNullOrUndefinedOrEmptyString(this.baselineTask.taskRiskNotes)) {
                html += `<tr><td>${this.l('TaskRiskNotes')}:</td><td>${this.trimHTML(this.baselineTask.taskRiskNotes)}</td>`;
                if (compareTasks) {
                    html += `<td class="${this.classCompare1(this.currentTask.taskRiskNotes, this.baselineTask.taskRiskNotes)}">${this.trimHTML(this.currentTask.taskRiskNotes)}</td>`;
                }
                html += '</tr>';
            }
        }

        html += '</table>';
        this.taskTooltipContent = html;
    }
}
